import React from 'react'
import PropTypes from 'prop-types'

import Image from './../../Default/Image'
import './ServiceHeader.css'

const ServiceHeader = ({ title, backgroundImage, large, className = '' }) => {
  if (large) className += ' ServiceHeader-large'
  return (
    <div className={`ServiceHeader relative ${className}`}>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
          className="underline"
        />
      )}
      <div className="container relative">
        <h1 className="ServiceHeader--Title">{title}</h1>
      </div>
    </div>
  )
}

ServiceHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ServiceHeader
