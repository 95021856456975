import React from 'react'
import ChevronDown from 'react-feather/dist/icons/chevron-down'
import _kebabCase from 'lodash/kebabCase'
import './SingleAccordion.css'
import * as S from './../styled'

export const SingleAccordion = ({ items = [], className = '' }) => {
  const handleClick = event => {
    event.target.classList.toggle('active')
  }
  return (
    <div className={`ServiceAccordion ${className}`}>
      <div className={`ServiceAccordion--wrap row justify-content-center`}>
        {!!items &&
          items.map((item, index) => (
            <div
              className="col-lg-4"
              key={`accordion-item-${_kebabCase(item.title) + '-' + index}`}
            >
              <div className={`ServiceAccordion--item`} onClick={handleClick}>
                <h2 className="flex">
                  <span>{item.title}</span>
                  <ChevronDown />
                </h2>
                <div className={'description'}>
                  {item.description} <br />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export const Description = ({ description, slug }) => {
  return (
    <>
      <S.P>{description}</S.P>
      <S.Link className="button-primary" right href={slug} btn>
        Více
      </S.Link>
    </>
  )
}
